import Vue from 'vue';
import VueRouter from 'vue-router';
import BoothBase from '@/views/booths/BoothBase.vue';
import EventBase from '../views/EventBase.vue';
import Home from '../views/Home.vue';
import Login from '../views/Login.vue';
import Agenda from '../views/Agenda.vue';
import Live from '../views/Live.vue';
import Sponsors from '../views/Sponsors.vue';
import Certificate from '../views/Certificate.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/',
    component: EventBase,
    children: [
      {
        path: '/',
        name: 'Home',
        component: Home,
      },
      {
        path: '/agenda',
        name: 'Agenda',
        component: Agenda,
      },
      {
        path: '/sponsors',
        name: 'Sponsors',
        component: Sponsors,
      },
      {
        path: '/live/:id',
        name: 'Live',
        component: Live,
      },
      {
        path: '/booth/:id',
        name: 'Booth',
        component: BoothBase,
      },
      {
        path: '/certificate',
        name: 'Certificate',
        component: Certificate,
      },
    ],
  },

  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
